<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                v-show="!bookingDetails.hasOwnProperty('zone')"
                height="260px"
                class="rounded-t-lg"
                type="image"
              ></v-skeleton-loader> -->

            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="
              bookingDetails.hasOwnProperty('zone') &&
                bookingDetails.zone.image != '' &&
                bookingDetails.zone.image != null
                ? bookingDetails.zone.image
                : zoneImgURL
            " :lazy-src="
  bookingDetails.hasOwnProperty('zone') &&
    bookingDetails.zone.image != '' &&
    bookingDetails.zone.image != null
    ? bookingDetails.zone.image
    : zoneImgURL
" alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4">
              You are parked at
              {{
                bookingDetails.hasOwnProperty("zone")
                ? bookingDetails.zone.zoneName
                : ""
              }}
            </div>
          </div>
          <v-card-text class="pa-2">
            <v-form ref="detailsForm">
              <v-container fluid pa-0>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4 mt-2 text-center color-black" style="font-size: 12px">
                    <p class="mb-0 black--text" v-if="licencePlate == null || licencePlate == ''">
                      Add your license plate number for hassle-free parking
                    </p>
                    <p class="mb-0 black--text" v-else>
                      Confirm your license plate number for hassle-free parking
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">pin</v-icon>
                  </v-col>
                  <v-col cols="11" class="px-4">
                    <v-text-field v-model="licencePlate" hide-details="auto" maxlength="10"
                      :rules="[rules.isRequired, rules.alphaNumeric]" label="License Plate" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col cols="1" class="text-right align-self-end">
                    <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                  </v-col>
                  <v-col cols="11" class="px-4">
                    <v-text-field v-model="contact" hide-details="auto" disabled maxlength="10" :rules="[
                      rules.isRequired,
                      rules.isNumeric,
                      rules.exactCharacters(10),
                    ]" label="Phone Number" clearable></v-text-field>
                  </v-col>
                  <v-col cols="1">
                  </v-col>
                  <v-col cols="11" class="px-4">
                    <span style="font-size:10px">Phone number is mandatory for generating the receipt</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center py-8">
            <v-container class="pa-0" fluid>
              <v-row no-gutters v-if="licencePlate == null || licencePlate == ''">
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    :loading="addLicencePlateBtnLoading" :disabled="disableAddLicencePlateBtn"
                    @click="updateLoadedSessionPlate()">Continue</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters v-else>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    :loading="addLicencePlateBtnLoading" :disabled="disableAddLicencePlateBtn"
                    @click="updateLoadedSessionPlate()">Confirm</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="mt-2 text-center color-black" style="font-size: 12px">
                  <p class="mb-0">
                    A valid credit card is required to park. Without one, you
                    could be subject to a parking violation and issued a
                    citation.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmCreateSessionDialog" max-width="fit-content">
      <v-card class="pa-0">
        <v-card-title class="justify-center">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">
            We couldn’t find your plate. Please try entering it again.
          </span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="confirmCreateSessionDialog = false">OK</v-btn>
              </v-col>
              <!-- <v-col cols="12" class="mt-4">
                        <v-btn
                          rounded
                          block
                          elevation="0"
                          class=""
                          @click="closeConfirmCreateSessionDialog()"
                          >Close</v-btn
                        >
                      </v-col> -->
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import rules from "@/utils/rules";
import { EventBus } from "@/lib/EventBus";
// import { format } from "date-fns";
// import loggerHelper from "../loggerHelper";
// import { mapGetters } from "vuex";
export default {
  name: "CreateSession",
  components: {},
  data: () => ({
    contactOption: "+1",
    rules: rules,
    licencePlate: "",
    contact: "",
    addLicencePlateBtnLoading: false,
    confirmCreateSessionDialog: false
  }),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
    }),
    disableAddLicencePlateBtn() {
      return this.contact == "" ||
        this.contact == null ||
        this.licencePlate == "" ||
        this.licencePlate == null ||
        this.contact.length != 10 ||
        /^[a-z0-9]+$/i.test(this.licencePlate) === false
        ? true
        : false;
    },
  },
  watch: {
    bookingDetails() {
      this.licencePlate = this.bookingDetails?.booking?.vehicleNo
        ? this.bookingDetails?.booking?.vehicleNo
        : "";
      this.contact = this.bookingDetails?.user?.contact
        ? this.bookingDetails.user.contact.length > 10
          ? this.bookingDetails.user.contact.substring(
            this.bookingDetails.user.contact.length - 10
          )
          : this.bookingDetails.user.contact
        : "";
      this.confirmCreateSessionDialog = (this.bookingDetails?.booking?.isLPRVerified && this.bookingDetails.booking.isLPRVerified == "0") ? true : false
    },
  },
  created() { },
  async mounted() {
    this.licencePlate = this.bookingDetails?.booking?.vehicleNo
      ? this.bookingDetails?.booking?.vehicleNo
      : "";
    this.contact = this.bookingDetails?.user?.contact
      ? this.bookingDetails.user.contact.length > 10
        ? this.bookingDetails.user.contact.substring(
          this.bookingDetails.user.contact.length - 10
        )
        : this.bookingDetails.user.contact
      : "";
    this.contactOption =
      this.bookingDetails?.user?.contact &&
        this.bookingDetails.user.contact.length > 10
        ? this.bookingDetails.user.contact.substring(
          0,
          this.bookingDetails.user.contact.length - 10
        )
        : this.contactOption;
    this.confirmCreateSessionDialog = (this.bookingDetails?.booking?.isLPRVerified && this.bookingDetails.booking.isLPRVerified == "0") ? true : false
  },
  destroyed() { },
  methods: {
    /**
     * @method updateLoadedSessionPlate update license plate of the loaded session
     */
    async updateLoadedSessionPlate() {
      this.addLicencePlateBtnLoading = true;
      let postObj = { vehicleNo: this.licencePlate.toUpperCase(), webView: 1 };
      try {
        var updatePlate = await APIHelper(
          "PATCH",
          "/api/v1/modifyLPR/" + this.bookingId,
          postObj
        );
        this.addLicencePlateBtnLoading = false;
        console.log(updatePlate);
        if (updatePlate?.data?.status && updatePlate.data.status == true) {
          await this.getBookingDetails(this.bookingId);
          await this.$router.replace({ path: "/checkedin" });
          EventBus.$emit('GET_FREE_BOOKING')
        }
      } catch (error) {
        this.addLicencePlateBtnLoading = false;
        console.log(error);
      }
    },
    /**
     * @method getBookingDetails fetch the booking details and commit SET_BOOKING_DETAILS mutation.
     */
    async getBookingDetails(bookingId) {
      try {
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 30px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}
</style>
